<template>
  <div>    
      <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.theme--light.v-application {
  background: #000000 !important;
}
</style>
