<template>
  <div class="bg">
    <transition name="fade">
      <div v-if="pages[0]">
        <img
          style="position: absolute; width: 100%; height: 100%"
          src="../assets/1_首頁.png"
          @click="selectPage(1)"
        />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="pages[1]">
        <img
          style="position: absolute; width: 100%; height: 100%"
          src="../assets/2_文字留言.png"
        />
        <textarea
          style="
            position: absolute;
            left: 24%;
            width: 52%;
            height: 26%;
            top: 45%;
            font-size: 7vw;
            padding: 0;
            border: 0;
          "
          v-model="postMessage"
          placeholder="(上限12字)"
          maxlength="12"
        ></textarea>
        <map
          style="
            position: absolute;
            left: 24%;
            width: 52%;
            height: 5%;
            top: 79%;
          "
          @click="selectPage(2)"
        ></map>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="pages[2]">
        <img
          style="position: absolute; width: 100%; height: 100%"
          src="../assets/3_確認畫面.png"
        />
        <textarea
          style="
            position: absolute;
            left: 24%;
            width: 52%;
            height: 26%;
            top: 45%;
            font-size: 7vw;
            padding: 0;
            border: 0;
          "
          readonly
          v-model="postMessage"
        ></textarea>
        <map
          style="
            position: absolute;
            left: 24%;
            width: 52%;
            height: 5%;
            top: 79%;
          "
          @click="Send"
        ></map>
        <map
          style="
            position: absolute;
            left: 24%;
            width: 52%;
            height: 5%;
            top: 87%;
          "
          @click="selectPage(1)"
        ></map>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="pages[3]">
        <img
          style="position: absolute; width: 100%; height: 100%"
          src="../assets/4_留言完成.png"
        />
      </div>
    </transition>
    <div v-if="isProcessing" class="mask">
      <p
        style="
          font-size: 7vw;
          color: white;
          position: absolute;
          top: 64vw;
          left: 40vw;
        "
      >
        上傳中...
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      pages: [true, false, false, false],
      postMessage: "",
      isProcessing: false,
    };
  },
  methods: {
    selectPage(pageindex) {
      if (pageindex == 4) {
        window.scrollTo(0, 0);
      }
      if (pageindex == 1) {
        this.postMessage = "";
      }
      for (var i = 0; i < this.pages.length; i++) {
        if (i == pageindex) {
          this.pages[i] = true;
        } else {
          this.pages[i] = false;
        }
      }
      this.$forceUpdate();
    },
    async Send() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      var url = `https://message-admin.13api.me/api/upload`;
      axios({
        url: url,
        method: "post",
        data: {
          content: this.postMessage,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          if (res.data.success) {
            this.isProcessing = false;
            this.selectPage(3);
            await this.Delay(3000);            
            this.selectPage(0);
          } else {
            alert("上傳失敗![success=false]");
            this.isProcessing = false;
            this.selectPage(0);
          }          
        })
        .catch((err) => {
          alert("上傳失敗!" + `[${err}]`);
          this.isProcessing = false;
          this.selectPage(0);          
        });
    },
    Delay(interval) {
      return new Promise((resolve) => {
        setTimeout(resolve, interval);
      });
    },
  },
};
</script>
<style scoped>
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 177vw;
  background-color: #55555555;
  z-index: 3;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 177vw;
  overflow: hidden;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
</style>
